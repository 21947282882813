import React, {useEffect, useRef} from "react"
import {tryToLoad} from "../helpers"


const navOptions = {
  'draggable' : false 
};

const SideNav = (props, ref) => {

  const sideNavRef = useRef(null);

  useEffect(() => { 
    tryToLoad( () => window.M.Sidenav.init(sideNavRef.current , navOptions));    
  },[]);

  return(
    <div ref = {sideNavRef} {...props}>
      <a className="sidenav-close"></a>
      <ul>
    <li><a href="https://www.artmaisons.gr/accommodation/aspaki-exclusive-hotel/" target="_blank">Aspaki Exclusive Hotel</a></li>
    <li><a href="https://www.artmaisons.gr/accommodation/oia-castle-luxury-suites/" target="_blank">Oia Castle Luxury Suites</a></li>
    <li><a href="https://www.artmaisons.gr/contact-us/" target="_blank">Contact</a></li>
  </ul>
  <ul>
    <li><strong>Art Maisons Exclusive Hotels</strong></li>
    <li>Oia Santorini</li>
    <li>84702, Greece</li>
    <li><a href="tel:+302286071831">T. +30 22860 71831 </a></li>
    <li>F. +30 22860 72312</li>
    <li><a href="mailto:info@artmaisons.gr">E. info@artmaisons.gr</a></li>
  </ul>
    </div>
  );
};


export default SideNav;