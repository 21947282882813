import React from "react"


const Hero = ({image}) => {

  const style = {
    background: `url(${image}) center center / cover`
  };

  return(
    <section className="hero row valign-wrapper" style={style}>
        <h1>Stay Safe<br />
           Start e-Checkin</h1>
    </section>
  )
};


export default Hero;