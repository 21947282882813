import React, {useEffect, Suspense, lazy} from "react"

import {
  HashRouter as Router,
  Switch,
  Route,
  Link  
} from "react-router-dom";

import { createBrowserHistory } from 'history';


import {appendScript} from "../helpers/appendScript"
import Layout from "./layout"
import Home from "../views/Home"

const Scan = lazy(() => import("../views/Scan"))
const Overview = lazy(() => import("../views/Overview"))
const Finish = lazy(() => import("../views/Finish"))
const ReservationData = lazy(() => import("../views/ReservationData"));
const Signature = lazy(() => import("../views/Signature"));
const PassportScanner = lazy( () => import("../views/PassportScanner"));

const loading = <div></div>

const history = createBrowserHistory();


history.listen( location => {

  if(typeof window.gtag != 'undefined' && window.settings.gaid){
    window.gtag('config', window.settings.gaid, {'page_path': location.location.pathname + location.location.hash});
  }  

  window.scrollTo(0, 0);
  
});


const MainPage = (props) => {
  useEffect( () => {
    appendScript(`https://hoteligent.co/js/bin/materialize.min.js`);
    //appendScript(`https://checkin.webolution.co/opencv.js`);
  },[]);
  
  return <Layout> 
  <Router> 
  <Suspense fallback = {loading}>
      <Switch>
        <Route exact path =  "/">
          <Home />
        </Route>  
        <Route path = "/scan22/:reservationNumber" component = {Scan} />
        <Route path = "/overview/:reservationNumber" component = {Overview} />
        <Route path = "/reservation_data/:reservationNumber" component = {ReservationData} />
        <Route path = "/signature" component = {Signature} />
        <Route path = "/finish" component = {Finish} />        
        <Route path = "/scan/:reservationNumber" component = {PassportScanner} />
      </Switch>
    </Suspense>
   </Router> 
  </Layout>
};


export default MainPage;