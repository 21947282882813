import React from "react"

const Layout = (props) => {
  return ( 
    <div id="wrapper" className="z-depth-4">
        <a data-target="slide-out" className="sidenav-trigger show-on-large">
          <div></div>
        </a>
        { props.children }
    </div> 
  );  
}

export default Layout;