import React, {createContext, useReducer} from 'react';

import {ADD_PASSPORT, CLEAR_PASSPORTS,DELETE_PASSPORT, SET_CHECKIN_ID} from "./actions";



//setup the initial state
const initialState = {
  checkin_id: '',
  passports: [],
};

//create the store
const store = createContext(initialState);

const {Provider} = store;


const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {

    console.log('WEBO: State: Action');
    console.log('WEBO: State: Type:' + action.type);
    console.log('WEBO: State:', action.payload || '');
    console.log('WEBO: State: End Action');

    switch(action.type) {
      case ADD_PASSPORT:
        const passports = [...state.passports, action.payload];
        return Object.assign(state, {passports});
      case CLEAR_PASSPORTS:  
        return Object.assign(state, {passports:[]});        
      case SET_CHECKIN_ID:
        return Object.assign(state, {checkin_id:action.payload});
      case DELETE_PASSPORT:
       
        return Object.assign(state, {passports: state.passports.filter(
          passport => passport.id != action.payload
          )});
      default:
        throw new Error("ACTION NOT FOUND");        
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};


export {store, StateProvider}