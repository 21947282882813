export const tryToLoad = (fn) => {
    const interval = 300;
    let timer = null;

    if(typeof fn != 'function'){
      throw "Invalid function";
    }

    timer = window.setInterval(() => {
      try{
        fn();
        window.clearInterval(timer);
      }catch(Error){}

    }, interval);
}


export const handleFetchErrors = (response) => {

  if (!response.ok) {      
      throw {
          statusText: response.statusText,
          status: response.status,
          jsonData: response.json(),
       };
  }

  return response;
}

export const scrollToRef = ref => {
  window.scrollTo(0, ref.current.offsetTop + 20);
}