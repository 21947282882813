import React, { useState, useEffect, useRef, useContext} from "react"
import { useHistory,Link } from "react-router-dom";

import HeaderLogo from "../components/HeaderLogo"
import Hero from "../components/Hero"
import SideNav from "../components/SideNav"
import SideNavButton from "../components/SideNavButton"
import {tryToLoad, handleFetchErrors} from "../helpers"


import {store} from "../store";
import {SET_CHECKIN_ID} from "../store/actions";


const Home = (props) => {

  const [reservationId, setReservationId] = useState('');
  const [termsChecked,  setTermsChecked]  = useState(false);
  const [hasTermsError, setTermsError]    = useState(false);
  const [isLoading,setLoading]            = useState(false);

  const modalRef = useRef();


  const globalState = useContext(store);

  const {
    state, dispatch
  } = globalState;

  const history = useHistory();
  
  const handleFormSubmit = e => {

    e.preventDefault();

    if(!termsChecked){
      setTermsError(true);
      alert("You must agree with Terms and Conditions to continue.");
      return;
    }

    setLoading(true);

    fetch(`${window.settings.apiUrl}/reservation/search?reservationNumber=${reservationId}&apikey=${window.settings.apikey}`)

    .then(handleFetchErrors)

    .then(response => response.json())

    .then(response => {

      let new_url = `/scan/${reservationId}`;

      if(typeof response.type != 'undefined'){
        new_url += new_url+'?type=custom';
      }

      if(response.checkin_id){
        dispatch({
          type:SET_CHECKIN_ID,
          payload: response.checkin_id
        });
      }

      history.push(new_url);

      setLoading(false);
    })
    .catch(error => {
      setLoading(false);      
      alert("The reservation number not found. Please check your resevation id and try again.");      
    }); 
  };

  useEffect(() => {

    tryToLoad( () => window.M.Modal.init(modalRef.current,{}));

    return () => {
     // window.M.Modal.getInstance(modalRef.current).destroy();
    }
  },[]);

  
  const onTermsClick = e =>{
    e.preventDefault();    
  }

  let btnClass = 'btn';

  if(isLoading){
    btnClass += ' disabled';
  }

  return(
    <>
     
     <HeaderLogo image = {window.settings.images.logo} />

     <SideNavButton />

     <SideNav id="slide-out" className="sidenav" />

     <Hero image = {window.settings.images.hero} /> 
     
     <section className="row margin-top">
      <form className="row margin-top" id="reservation-form" onSubmit = {handleFormSubmit}>
        <div className="row align-center">
          <p>{window.settings.messages.welcome}</p>
        </div>
        <div className="row margin-top">
          <div className="input-field only-child">
            <input id="reservation_id" type="number" className="validate float-none" value = {reservationId} onChange = {e => setReservationId(e.target.value)}/>
            <label htmlFor="reservation_id">Reservation ID</label>
          </div>
          <div className="input-field only-child">
            <label className="checkbox">
              <input id="acceptance" type="checkbox" className="filled-in validate invalid" checked = {termsChecked} onChange = {e=> {setTermsChecked(e.target.checked);setTermsError(false)}}/>          
              <span>I accept <a href = "#modal1" className = "modal-trigger">Terms & Privacy Policy</a></span> </label>              
          </div>
          <div className="input-field">
            <button className = {btnClass} type="submit" name="action">Start Check-In</button>
          </div>
        </div>
      </form>
    </section>  
    <div id="modal1" className="modal modal-fixed-footer" ref = {modalRef}>
      <div className="modal-content">
       <h2>Terms and Conditions</h2>
        <br />
        <p>
          TERMS AND CONDITIONS FOR THE USE OF THE ONLINE CHECK IN<br /><br />
          PLEASE READ CAREFULLY THESE BINDING TERMS AND CONDITIONS OF USE BEFORE USING THE ONLINE CHECKIN SERVICE<br /><br /></p>

          <br />
        <p>By accessing, browsing and using you acknowledge and agree to have read, understood and agreed to these Terms 
        A User warrants that he/she possesses the legal authority to enter into any agreement and to use the Platform. If you use one of the links on the Platform to visit third party websites or internet applications, then please note that your viewing of these sites or applications and any purchases made through them will be and are subject to separate terms and conditions.
        </p>
        <p></p>
      </div>
      <div className = "modal-footer">
        <a href="#" onClick = {e => setTermsChecked(false)} className = "modal-close waves-effect waves-red btn-flat">Disagree</a>
        <a href="#" onClick = {e => setTermsChecked(true)}  className = "modal-close waves-effect waves-green btn-flat">Agree</a>
      </div>
    </div>
    </>
  );
};


export default Home;